/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderMobile {
    classItem = 'header-mobile--item'
    classButton = 'header-mobile--button'

    classItemOpen = 'header-mobile---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
